<template>
  <div class="contents">
    <div class="col-lg-12">
      <div class="breadcrumb-main">
        <h4 class="text-capitalize breadcrumb-title">
          Topics
          <ul class="atbd-breadcrumb nav">
            <li class="atbd-breadcrumb__item">
              <a href="#">
                <span class="la la-home"></span>
              </a>
              <span class="breadcrumb__seperator">
                <span class="la la-slash"></span>
              </span>
            </li>
            <li class="atbd-breadcrumb__item">
              <a href="#">Metrics</a>
              <span class="breadcrumb__seperator">
                <span class="la la-slash"></span>
              </span>
            </li>
            <li class="atbd-breadcrumb__item">
              <span>Topics</span>
            </li>
          </ul>
        </h4>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="contact-list-wrap mb-25">
            <div class="contact-list bg-white radius-xl w-100">
              <div class="mb-2">
                <h3><small> Total Topics </small> {{ topicInfo.total }}</h3>
              </div>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label>Select class</label>
                    <v-select v-model="selectedClass" item-text="nick" item-value="cllid" label="nick"
                      :options="classes" @input="classSelected"></v-select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label>Select Subject</label>
                    <v-select v-model="selectedSubject" item-text="name" item-value="sulid" label="name"
                      :options="availableSubjects"></v-select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label>Select Scheme</label>
                    <v-select v-model="selectedScheme" :options="schemes"></v-select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label>Select Week</label>
                    <input type="number" class="form-control" v-model="selectedWeek" name="week" id="week" />
                  </div>
                </div>
              </div>
              <p class="bold">

                <span v-if="selectedClass.nick">Selecting {{ selectedClass.nick }} topics</span>
                <span v-if="selectedSubject.name"> in {{ selectedSubject.name }}</span>
                <span v-if="selectedScheme"> for {{ selectedScheme }} term </span>
                <span v-if="selectedWeek">(Week {{ selectedWeek }})</span>
              </p>
              <div><button class="btn btn-primary" @click="filterTopics">Filter</button></div>
              <div class="table-responsive mt-4 position-relative overflow-hidden" v-if="topics.length">

                <LoaderOverlay
                  :active="true"
                  v-if="isLoading"
                  :zIndex="99"
                  position="absolute"
                  style="height: 100%"
                />
                <table class="table mb-0 table-borderless table-rounded">
                  <thead>
                    <tr class="">
                      <th scope="col">
                        <span class="userDatatable-title">Name</span>
                      </th>
                      <th scope="col">
                        <span class="userDatatable-title">Scheme</span>
                      </th>
                      <th scope="col">
                        <span class="userDatatable-title">Week </span>
                      </th>
                      <th scope="col">
                        <span class="userDatatable-title float-right">Actions</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(t, k) in topics" :key="k" class="pointer" @click="gotoTopic(t)">
                      <td>
                        <div class="contact-item d-flex align-items-center">
                          {{ t.name }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="orderDatatable-title">
                            <p class="d-block mb-0">
                              {{ t.scheme }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="orderDatatable-title">
                            <p class="d-block mb-0">
                              {{ t.week }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <ul class="
                            orderDatatable_actions
                            mb-0
                            d-flex
                            flex-wrap
                            float-right
                          ">
                          <li>
                            <a class="remove" data-toggle="tooltip" data-placement="top" title="view">
                              <i class="las la-eye"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <no-content v-if="noData" />
            </div>
          </div>
        </div>
              <Pagination :url="topicUrl" @pageChange="pageChange" @loading="loading" :urlParam="urlParam" :size="size"/>
        <!-- ends: col-12 -->
      </div>
    </div>
  </div>
</template>
<script>

// import { apiClient } from "@/plugins/restclient.js";
import Pagination from "@/components/Pagination.vue";
import NoContent from "@/components/NoContent";
import LoaderOverlay from "@/components/LoaderOverlay";
import { mapGetters } from 'vuex';
export default {
  components: {
    Pagination,
    NoContent,
    LoaderOverlay
  },
  data() {
    return {
      topics: [],
      topicInfo: {},
      size: 20,
      selectedWeek: 0,
      selectedScheme: 0,
      selectedSubject: '',
      selectedClass: '',
      schemes: [1, 2, 3],
      availableSubjects: [],
      noData: false,
      paginationData: '',
      topicUrl: '/v1/office/frame/topic/summon',
      urlParam:'',
      url: '',
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      subjects: "allSubjects",
      classes: "allClasses",
    }),
  },
  mounted() {
    // this.getTopics()
  },
  watch: {
    selectedClass() {
      this.selectedSubject = ""
      this.selectedScheme = ""
      this.selectedWeek = ""
    },
    selectedSubject() {
      this.selectedScheme = ""
      this.selectedWeek = ""
    },
    selectedScheme() {
      this.week = ""
    },

  },
  methods: {
    gotoTopic(t){
      const url = ''
      this.$router.push({name: 'classes-topics-questions', params: { tid: t.tolid, tname: t.name, clsId: t.cllid, sid: t.sulid, url: url }})
    },
    classSelected() {
      this.availableSubjects = this.subjects.filter(s => s.cllid == this.selectedClass?.cllid)
    },
    filterTopics(){
      
      if (this.selectedClass) {
        this.urlParam += `&class=${this.selectedClass.cllid}`
      }
      if (this.selectedSubject) {
        this.urlParam += `&subject=${this.selectedSubject.sulid}`
      }
      if (this.selectedScheme) {
        this.urlParam += `&scheme=${this.selectedScheme}`
      }
      if (this.selectedWeek) {
        this.urlParam += `&week=${this.selectedWeek}`
      }
    },
    pageChange(dataSet, paginationData, res) {
      this.topicInfo = res.info;
      this.topics = dataSet;
      this.paginationData = paginationData;
      if (this.topics.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    },
    loading(val){
      this.isLoading = val
    }
  }
}
</script>